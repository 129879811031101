import React, { useState, Component } from "react";
import styled from "styled-components";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Modal,
  Select,
  DatePicker,
  Divider,
  Carousel,
  Rate,
  Tabs,
  Image,
} from "antd";
import { Link } from "react-router-dom";
//import { Modal as SuccessModal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";
import ImgCrop from "antd-img-crop";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const { TabPane } = Tabs;

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledDatePicker = styled(DatePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid lightgrey !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
const dateFormat = "YYYY/MM/DD";
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

class servicecenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBranch: "",
      // klerksdorpMails: "leads@megatheron.co.za",
      // klerksdorpMail: "service@megatheron.co.za",
      // potchefstroomMail: "potchservice@megatheron.co.za",
      // potchefstroomMail1: "potchservice@megatheron.co.za",
      // mahikengMail: "service1@megatheron.co.za",
      // mahikengMail2: "service1@megatheron.co.za",
      // mahikengMail3: "service1@megatheron.co.za",
      klerksdorpMails: "leads@megatheron.co.za",
      klerksdorpMail: "Klerksdorp",
      potchefstroomMail: "Potchefstroom",
      potchefstroomMail1: "Potchefstroom",
      mahikengMail: "Mahikeng",
      mahikengMail2: "Mahikeng",
      mahikengMail3: "Mahikeng",
      visibleGetQuote: false,
      visibleservicecenter: false,
      visibleFindPart: false,
      visible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  handleSubmitQuote = (values) => {
    this.onFinishQuote(values);
  };
  handleSubmitPart = (values) => {
    this.onFinishPart(values);
  };
  onFinishQuote = (values) => {
    const MSG = {
      // to: [values.branch,"leads@megatheron.co.za"],
      to: "leads@megatheron.co.za",
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New service quote request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Service Quote Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new service quote request from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.year}<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make}<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.model}<br />
                       
                          <strong> Derivative</strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.derivative}<br />
                          <strong> Details </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details}<br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
        <strong> Branch </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.branch}
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        visibleGetQuote: false,
        submitDisabled: true,
        year: "",
        make: "",
        model: "",
        derivative: "",
        name: "",
        number: "",
        email: "",
        branch: "",
        details: "",
      });
    });
  };
  onFinishPart = (values) => {
    const MSG = {
      // to: [values.branch,"leads@megatheron.co.za"],
      to: "leads@megatheron.co.za",
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New car part request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Car Part Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new car part request from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.year}<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make}<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.model}<br />
                       
                          <strong> Derivative</strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.derivative}<br />
                          <strong> Details </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details}<br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Branch </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.branch}
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        visibleFindPart: false,
        submitDisabled: true,
        year: "",
        make: "",
        model: "",
        derivative: "",
        name: "",
        number: "",
        email: "",
        branch: "",
        details: "",
      });
    });
  };

  onFinish = (values) => {
    const MSG = {
      // to: [values.branch,"leads@megatheron.co.za"],
      to: "leads@megatheron.co.za",
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New car service request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Car Service Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new car service request from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.year
        }<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make
        }<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.model
        }<br />
                       
                          <strong> Derivative</strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.derivative
        }<br />
                          <strong> Details </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details
        }<br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name
        }<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number
        }<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email
        }<br />
                          <strong> Date </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.date.format(
          dateFormat
        )}<br />
        <strong> Branch </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.branch}
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        visibleservicecenter: false,
        submitDisabled: true,
        year: "",
        make: "",
        model: "",
        derivative: "",
        name: "",
        number: "",
        email: "",
        branch: "",
        date: "",
        details: "",
      });
    });
  };

  //Jou Modals

  showModalGetQuote = () => {
    this.setState({
      visibleGetQuote: true,
    });
  };
  handleCancelGetQuote = () => {
    this.setState({
      visibleGetQuote: false,
    });
  };

  showModalservicecenter = () => {
    this.setState({
      visibleservicecenter: true,
    });
  };
  handleCancelservicecenter = () => {
    this.setState({
      visibleservicecenter: false,
    });
  };

  showModalFindPart = () => {
    this.setState({
      visibleFindPart: true,
    });
  };
  handleCancelFindPart = () => {
    this.setState({
      visibleFindPart: false,
    });
  };

  //Einde

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  // onChangeSlider(a, b, c) {
  onChange(a, b, c) {
    console.log(a, b, c);
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    function callback(key) {
      console.log(key);
    }

    return (
      <div>
        {window.isPhone != true ? (
          <div>
            <div
              style={{
                width: "100vw",
                margin: "auto",
              }}
            >
              <img
                style={{
                  width: "106vw",
                  position: "absolute",
                  height: window.isPhone ? "25em" : "31em",
                  objectFit: "cover",
                  marginTop: "0em",
                  right: 0,
                  zIndex: 0,
                }}
                src={`${process.env.PUBLIC_URL}/images/service-background-image.png`}
                alt="logo"
              />
              <div className="row " style={{ height: "31em" }}>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="banner-item-home  no-bg color-inher-home text-align-lg-right ">
                    <Row
                      style={{
                        margin: "auto",
                        paddingTop: "2em",
                        paddingBottom: "1em",
                      }}
                    >
                      <Col
                        xs={24}
                        md={12}
                        style={{
                          textAlign: "left",
                          paddingTop: "3em",
                        }}
                      >
                        <h1
                          style={{ color: "white", marginTop: "-0.4em" }}
                          className={
                            window.isPhone
                              ? "f-weight-600 f-20"
                              : "f-weight-600 f-20"
                          }
                        >
                          Welcome to Mega Theron{" "}
                          <span style={{ color: "red" }}>SERVICE CENTER</span>
                        </h1>
                        <h1
                          style={{
                            color: "white",
                            marginTop: window.isPhone ? "5px" : "5px",
                            paddingTop: 0,
                            fontSize: "43px",
                            fontWeight: 300,
                            textTransform: "none",
                            paddingBottom: 0,
                            marginBottom: window.isPhone ? "-10px" : "-15px",
                          }}
                        >
                          Discover the Mega Theron Difference Today
                        </h1>
                        <br />
                        <p
                          style={{
                            color: "white",
                            fontSize: "18.5px",
                            fontWeight: 300,
                            paddingTop: "1em",
                          }}
                        >
                          At Mega Theron, we're your trusted destination for
                          expert car care and
                          <br />
                          exceptional service. Our skilled mechanics and
                          affordable prices ensure your
                          <br /> vehicle receives the top-quality care it
                          deserves.
                          <br />
                          <br />
                          <span style={{ fontWeight: 500, fontSize: "14.5px" }}>
                            We provide service & parts for a wide range of
                            vehicle makes
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        position: "absolute",
                        marginTop: "-6em",
                        // left: "-20em"
                      }}
                    >
                      <Col xs={24} md={24}>
                        <div id="slideshow">
                          <div class="container1">
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/bmw-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/audi-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/chev-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/jac-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/ford-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/merc-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/ww-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/hyundai-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "11em",
                                  marginTop: "3em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/gwm-image-portrait.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/haval-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginTop: "2.2em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/chery-image-portrait.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/bmw-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/audi-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/chev-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/jac-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/ford-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/merc-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/ww-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/hyundai-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/gwm-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/haval-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/chery-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "17em",
                                  marginBottom: "-11.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/more-image.png`}
                              ></img>
                            </section>
                          </div>
                        </div>
                        {/* <img
                        src={`${process.env.PUBLIC_URL}/images/jac-image.png`}
                        alt="Logo"
                        style={{ width: "28em", margin: "auto", float: "left", marginRight: "-3em" }}
                      /> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "23.5em",
              }}
            >
              <Row
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100vw",
                  height: "23.5em",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Col
                  span={8}
                  style={{
                    backgroundColor: "#242424",
                    paddingTop: "4.5em",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 19,
                      fontWeight: 500,
                      color: "white",
                      marginBottom: "-1.1em",
                    }}
                  >
                    GET A QUOTE
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 41,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginBottom: "-0.3em",
                    }}
                  >
                    Easy & Hassle-free
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginTop: 0,
                      marginBottom: "-1em",
                    }}
                  >
                    Request your service quote
                  </h1>
                  <br />
                  {/* <Link to="/getQuote"> */}
                  <Button
                    onClick={this.showModalGetQuote}
                    style={{
                      fontFamily: "Roboto",
                      border: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderRadius: "2em",
                      backgroundColor: "#CB2228",
                      color: "white",
                      height: "47px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Get Quote
                  </Button>
                  {/* </Link> */}
                </Col>
                <Col
                  span={8}
                  style={{
                    backgroundImage: `url("/images/book-red-image.png")`,
                    paddingTop: "4.5em",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 19,
                      fontWeight: 500,
                      color: "white",
                      marginBottom: "-1.1em",
                    }}
                  >
                    SERVICE CENTER
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 41,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginBottom: "-0.3em",
                    }}
                  >
                    Book a Service
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginTop: 0,
                      marginBottom: "-1em",
                    }}
                  >
                    Car in need of a service? Book one now
                  </h1>
                  <br />
                  {/* <Link to="/bookService"> */}
                  <Button
                    onClick={this.showModalservicecenter}
                    style={{
                      fontFamily: "Roboto",
                      border: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderRadius: "2em",
                      backgroundColor: "white",
                      color: "#CB2228",
                      height: "47px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                    type="primary"
                  // htmlType="submit"
                  >
                    Book a Service
                  </Button>
                  {/* </Link> */}
                </Col>
                <Col
                  span={8}
                  style={{
                    backgroundColor: "#242424",
                    paddingTop: "4.5em",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 19,
                      fontWeight: 500,
                      color: "white",
                      marginBottom: "-1.1em",
                    }}
                  >
                    FIND A PART
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 41,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginBottom: "-0.3em",
                    }}
                  >
                    Get the Perfect Part
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginTop: 0,
                      marginBottom: "-1em",
                    }}
                  >
                    Let us find the part you need
                  </h1>
                  <br />
                  {/* <Link to="/getPart"> */}
                  <Button
                    onClick={this.showModalFindPart}
                    style={{
                      fontFamily: "Roboto",
                      border: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderRadius: "2em",
                      backgroundColor: "#CB2228",
                      color: "white",
                      height: "47px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                    type="primary"
                  // htmlType="submit"
                  >
                    Get Your Part
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            </div>
            <Row
              style={{
                margin: "auto",
                maxWidth: "85em",
              }}
            >
              <Col
                span={24}
                style={{
                  paddingTop: "3em",
                  textAlign: "left",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: 52,
                    textTransform: "none",
                  }}
                >
                  Why <span style={{ color: "red" }}>Choose Us?</span>
                </h1>
              </Col>
            </Row>
            <Row
              style={{
                margin: "auto",
                maxWidth: "85em",
                paddingBottom: "5em",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/service-sider-image.png`}
                width={530}
                height={415}
                alt="sider-image-car"
                style={{
                  marginTop: "-14em",
                  width: "28em",
                  height: "24em",
                  zIndex: 1,
                  right: 0,
                  position: "absolute",
                }}
              />
              <Col span={8} style={{}}>
                <img
                  style={{
                    display: "flex",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/mechanic-image.png`}
                  width={46}
                  height={54}
                  alt="mechanic-image"
                />
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 26,
                    fontWeight: 500,
                    textTransform: "none",
                    marginTop: "-2.7em",
                    paddingLeft: "2.5em",
                  }}
                >
                  Top-Notch Service
                </h1>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    paddingLeft: "4.5em",
                    marginTop: "-1em",
                    lineHeight: "1.2em",
                  }}
                >
                  Our service center is staffed with highly <br />
                  skilled and experienced mechanics who are
                  <br /> dedicated to providing top-notch service for <br />
                  your vehicle.
                </p>
              </Col>
              <Col
                span={16}
                style={{
                  paddingLeft: "2em",
                  zIndex: 2,
                }}
              >
                <img
                  style={{
                    display: "flex",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/tag-image.png`}
                  width={50}
                  height={65}
                  alt="mechanic-image"
                />
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 26,
                    fontWeight: 500,
                    textTransform: "none",
                    marginTop: "-3.1em",
                    paddingLeft: "2.5em",
                  }}
                >
                  Affordable Excellence
                </h1>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    paddingLeft: "4.5em",
                    marginTop: "-1em",
                    lineHeight: "1.2em",
                  }}
                >
                  At our service center, we believe that
                  <br /> exceptional car care should be <br />
                  accessible to all.
                </p>
              </Col>
            </Row>
            <Row
              style={{
                margin: "auto",
                maxWidth: "85em",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: 52,
                    textTransform: "none",
                  }}
                >
                  Meet the <span style={{ color: "red" }}>Team</span>
                </h1>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    marginTop: "-2.55em",
                    lineHeight: "1.2em",
                  }}
                >
                  We're dedicated to ensuring that superior automative service
                  is within <br />
                  reach for all our customers.
                </p>
              </Col>
              <img
                src={`${process.env.PUBLIC_URL}/images/sider-image-left.png`}
                alt="logo"
                style={{
                  marginTop: "0em",
                  zIndex: -1,
                  left: 0,
                  position: "absolute",
                  width: "40vw",
                }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/sider-image-right.png`}
                alt="logo"
                style={{
                  zIndex: -1,
                  right: 0,
                  marginTop: "0em",
                  position: "absolute",
                  width: "40vw",
                }}
              />
            </Row>
            <Tabs
              defaultActiveKey="1"
              onChange={callback}
              style={{
                margin: "auto",
                textAlign: "center",
              }}
            >
              <TabPane tab="Klerksdorp" key="1">
                <Row
                  gutter={10}
                  style={{
                    margin: "auto",
                    marginTop: "3em",
                  }}
                >
                  {/* <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/11-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/3-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  {/* <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/6-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/8-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/12-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  {/* <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/7-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  {/* <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/5-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  {/* <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/1-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/2-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/4-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/9-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/10-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/13-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/14-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Mahikeng" key="2">
                <Row
                  gutter={10}
                  style={{
                    margin: "auto",
                    marginTop: "3em",
                  }}
                >
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/5-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/4-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/1-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/6-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/8-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/7-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/2-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/3-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/9-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={6} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/10-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>

            <Row
              style={{
                marginTop: "7.5em",
                // margin: "auto",
                // maxWidth: "85em",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: 52,
                    textTransform: "none",
                  }}
                >
                  Testimonials
                </h1>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    marginTop: "-2.55em",
                    lineHeight: "1.2em",
                  }}
                >
                  Discover what our customers have to say about their
                  experiences with us
                </p>
              </Col>
            </Row>
            <Row
              style={{
                // width: "100vw",
                marginTop: "2em",
                marginBottom: "2em",
                // backgroundColor: "#F5F5F5DE",
                // opacity: 0.7,
                // margin: "auto",
                // maxWidth: "85em",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <Slider {...settings}>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Jeanette Rossouw
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Wow, excellent service from Chery Klerksdorp and
                      Reghardt. From getting information to the delivery of the
                      vehicle, it was flawless. What a great pleasure doing
                      business with you guys! Thank you to the whole team at
                      Chery Klerksdorp, especially Reghardt. Well done! I will
                      definitely recommend this branch."
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Lebo M.
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Hi, good day! GWM Klerksdorp provided such marvelous
                      service. I'm truly at a loss for words to express my
                      appreciation. <br />
                      The level of service we received goes beyond mere words.
                      <br />
                      Thank you."
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Lucky Z.
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Thanks for the service you rendered to me since the start
                      of our journey in the beginning of December last year.
                      Today I got the beautiful beast delivered to me. I am in
                      love with it and I enjoy everything! The luxurious and
                      comfortable interior of this machine drives me crazy. It
                      makes me feel like I'm in heaven.
                      <br />
                      Thank You."
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Debra
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "I met Norman today, but first via a phone call early this
                      morning and later via Whatsapp. Our Hawk bakkie needed its
                      first service and one small other problem. In no time
                      everything was arranged and done. Professional yet kind
                      and warm service, that is what we received! Thank you
                      Norman, your friendly smile has hooked this customer for a
                      long time! Thank you very much. And wow thanks for our
                      special gifts."
                    </p>
                  </div>

                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Nokuthula M.
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "I bought my car at Mega Theron Traders... since 2022 no
                      problems. I'm coming for the second ride soon"
                    </p>
                  </div>
                </Slider>
              </Col>
            </Row>
            {/* <Form
              style={{ maxWidth: "80vw", margin: "auto" }}
              id='book-service'
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name='wrap'
              labelCol={{ flex: "110px" }}
              labelAlign='left'
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <section className='m-t-lg-30 m-t-xs-0 m-b-lg-50'>
                <div>
                  <div className='row'>
                    <div className='col-md-12 col-lg-12'>
                      <div
                        className='bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15'
                        style={{
                          
                          borderRadius: "1.5em",
                          backgroundColor: "white",
                        }}
                      >
                        <div className='m-b-lg-10'>
                          <div className='heading-1 text-align-lg-center'>
                            <h3>Book Vehicle Service</h3>
                          </div>
                          <p className='m-b-lg-20 text-align-lg-center'>
                            Fill in the form below and one of our stock
                            specialists will get in touch
                          </p>
                        </div>

                        <div className='m-b-lg-20'>
                          <div className='heading-1'>
                            <h3>Car Details </h3>
                          </div>

                          <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='year'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Year' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='make'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Make' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='model'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Model' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='derivative'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Derivative' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <Form.Item
                                name='details'
                                rules={[{ required: false, message: false }]}
                                hasFeedback
                              >
                                <StyledTextArea
                                  placeholder='Anything we need to know about your vehicle?'
                                  showCount
                                  maxLength={250}
                                  style={{
                                    height: 120,
                                    border: "1px solid black",
                                  }}
                                //onChange={onChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className='m-b-lg-20'>
                          <div className='heading-1'>
                            <h3>Contact Details</h3>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='name'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Name' />
                              </Form.Item>
                            </div>

                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='number'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Number' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: true,
                                    message: false,
                                    type: "email",
                                  },
                                ]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Email' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <Form.Item
                                name='branch'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledSelect
                                  bordered={false}
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "0em",
                                    height: 50,
                                  }}
                                  placeholder='Branch'
                                //onChange={this.setCarEngineCapacity}
                                >
                                  <OptGroup label='Klerksdorp'>
                                    <Option
                                      key={0}
                                      value={this.state.klerksdorpMail}
                                    >
                                      Chery (Klerksdorp)
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label='Potchefstroom'>
                                    <Option
                                      key={1}
                                      value={this.state.potchefstroomMail}
                                    >
                                      GWM (Potchefstroom)
                                    </Option>
                                    <Option
                                      key={2}
                                      value={this.state.potchefstroomMail1}
                                    >
                                      Haval (Potchefstroom)
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label='Mahikeng'>
                                    <Option
                                      key={3}
                                      value={this.state.mahikengMail}
                                    >
                                      Kia (Mahikeng)
                                    </Option>
                                    <Option
                                      key={4}
                                      value={this.state.mahikengMail1}
                                    >
                                      Mahindra (Mahikeng)
                                    </Option>
                                    <Option
                                      key={5}
                                      value={this.state.mahikengMail2}
                                    >
                                      GWM (Mahikeng)
                                    </Option>
                                    <Option
                                      key={6}
                                      value={this.state.mahikengMail3}
                                    >
                                      Haval (Mahikeng)
                                    </Option>
                                  </OptGroup>
                                </StyledSelect>
                              </Form.Item>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='date'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledDatePicker
                                  //defaultValue={moment(moment(), dateFormat)}
                                  format={dateFormat}
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </div>

                            <div className='col-lg-12 m-t-lg-12 text-center'>
                              <Form.Item>
                                <Button
                                  style={{
                                    
                                    border: "1px solid #F91700",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    borderRadius: "0.3em",
                                    backgroundColor: "#F91700",
                                    color: "white",
                                    height: "55px",

                                    paddingLeft: "3em",
                                    paddingRight: "3em",
                                    textShadow: "none",
                                    margin: "auto",
                                    marginTop: "2em",
                                  }}
                                  type='primary'
                                  htmlType='submit'
                                >
                                  Submit
                                </Button>{" "}
                              </Form.Item>{" "}
                              <p
                                style={{
                                  color: "black",
                                  //marginBottom: "2.5em",
                                  marginTop: "0em",
                                  fontSize: "13px",
                                  lineHeight: "1.5em",
                                }}
                              >
                                By submitting this form, you agree to our{" "}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link style={{ color: "black" }} to='/terms'>
                                    Terms of Use
                                  </Link>
                                </span>{" "}
                                and{" "}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link
                                    style={{ color: "black" }}
                                    to='/privacy-policy'
                                  >
                                    Privacy Statement
                                  </Link>
                                </span>
                                .
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                      alt='logo'
                      style={{
                        marginTop: "0em",
                        zIndex: -1,
                        left: 0,
                        position: "absolute",
                        width: "40vw",
                      }}
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                      alt='logo'
                      style={{
                        zIndex: -1,
                        right: 0,
                        marginTop: "0em",
                        position: "absolute",
                        width: "40vw",
                      }}
                    />
                  </div>
                </div>
              </section>
            </Form> */}
            <Modal
              visible={this.state.visible}
              style={{ textAlign: "center" }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Logo"
                style={{ width: "65%", margin: "auto" }}
              />
              <h3
                style={{
                  marginTop: "1em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Thank you for contacting
                <br />
                <span style={{ color: "rgb(214, 54, 41)" }}>
                  The Mega Theron Group.
                </span>{" "}
                <br />
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly.
                </span>{" "}
                <br />
              </h3>{" "}
              <br />
            </Modal>
            <Modal
              width={1000}
              style={{ top: 20 }}
              // title="Get a Quote"
              visible={this.state.visibleGetQuote}
              onCancel={this.handleCancelGetQuote}
            >
              <Form
                style={{ maxWidth: "80vw", margin: "auto" }}
                id="book-service"
                onFinish={this.handleSubmitQuote}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div
                          className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                          style={{
                            borderRadius: "1.5em",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="m-b-lg-10">
                            <div className="heading-1 text-align-lg-center">
                              <h3>GET A QUOTE</h3>
                            </div>
                            <p className="m-b-lg-20 text-align-lg-center">
                              Fill in the form below and one of our stock
                              specialists will get in touch
                            </p>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Car Details </h3>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="year"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Year" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="make"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Make" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="model"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Model" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="derivative"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Derivative" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Item
                                  name="details"
                                  rules={[{ required: false, message: false }]}
                                  hasFeedback
                                >
                                  <StyledTextArea
                                    placeholder="How can we assist with your vehicle today?"
                                    showCount
                                    maxLength={250}
                                    style={{
                                      height: 120,
                                      border: "1px solid black",
                                    }}
                                  //onChange={onChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="name"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Name" />
                                </Form.Item>
                              </div>

                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="number"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Number" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: false,
                                      type: "email",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Email" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <Form.Item
                                  name="branch"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledSelect
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                    placeholder="Region"
                                  //onChange={this.setCarEngineCapacity}
                                  >
                                    <OptGroup label="Klerksdorp">
                                      <Option
                                        key={0}
                                        value={this.state.klerksdorpMail}
                                      >
                                        Klerksdorp
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Potchefstroom">
                                      <Option
                                        key={1}
                                        value={this.state.potchefstroomMail}
                                      >
                                        Potchefstroom
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Mahikeng">
                                      <Option
                                        key={3}
                                        value={this.state.mahikengMail}
                                      >
                                        Mahikeng
                                      </Option>
                                    </OptGroup>
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              {/* <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="date"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledDatePicker
                                    //defaultValue={moment(moment(), dateFormat)}
                                    format={dateFormat}
                                    style={{
                                      height: 50,
                                      border: "1px solid black",
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </div> */}

                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",

                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>{" "}
                                </Form.Item>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    //marginBottom: "2.5em",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/terms"
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/privacy-policy"
                                    >
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                        alt="logo"
                        style={{
                          marginTop: "0em",
                          zIndex: -1,
                          left: 0,
                          position: "absolute",
                          width: "40vw",
                        }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                        alt="logo"
                        style={{
                          zIndex: -1,
                          right: 0,
                          marginTop: "0em",
                          position: "absolute",
                          width: "40vw",
                        }}
                      /> */}
                    </div>
                  </div>
                </section>
              </Form>
            </Modal>
            <Modal
              width={1000}
              style={{ top: 20 }}
              // title="Book a Service"
              visible={this.state.visibleservicecenter}
              onCancel={this.handleCancelservicecenter}
            >
              <Form
                style={{ maxWidth: "80vw", margin: "auto" }}
                id="book-service"
                onFinish={this.handleSubmit}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div
                          className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                          style={{
                            borderRadius: "1.5em",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="m-b-lg-10">
                            <div className="heading-1 text-align-lg-center">
                              <h3>Book Vehicle Service</h3>
                            </div>
                            <p className="m-b-lg-20 text-align-lg-center">
                              Fill in the form below and one of our stock
                              specialists will get in touch
                            </p>
                          </div>
                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Car Details </h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="year"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Year" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="make"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Make" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="model"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Model" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="derivative"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Derivative" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Item
                                  name="details"
                                  rules={[{ required: false, message: false }]}
                                  hasFeedback
                                >
                                  <StyledTextArea
                                    placeholder="Anything we need to know about your vehicle?"
                                    showCount
                                    maxLength={250}
                                    style={{
                                      height: 120,
                                      border: "1px solid black",
                                    }}
                                  //onChange={onChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="name"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Name" />
                                </Form.Item>
                              </div>

                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="number"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Number" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: false,
                                      type: "email",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Email" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <Form.Item
                                  name="branch"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledSelect
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                    placeholder="Region"
                                  //onChange={this.setCarEngineCapacity}
                                  >
                                    <OptGroup label="Klerksdorp">
                                      <Option
                                        key={0}
                                        value={this.state.klerksdorpMail}
                                      >
                                        Klerksdorp
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Potchefstroom">
                                      <Option
                                        key={1}
                                        value={this.state.potchefstroomMail}
                                      >
                                        Potchefstroom
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Mahikeng">
                                      <Option
                                        key={3}
                                        value={this.state.mahikengMail}
                                      >
                                        Mahikeng
                                      </Option>
                                    </OptGroup>
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="date"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledDatePicker
                                    //defaultValue={moment(moment(), dateFormat)}
                                    format={dateFormat}
                                    style={{
                                      height: 50,
                                      border: "1px solid black",
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </div>

                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",

                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>{" "}
                                </Form.Item>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    //marginBottom: "2.5em",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/terms"
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/privacy-policy"
                                    >
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                        alt="logo"
                        style={{
                          marginTop: "0em",
                          zIndex: -1,
                          left: 0,
                          position: "absolute",
                          width: "40vw",
                        }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                        alt="logo"
                        style={{
                          zIndex: -1,
                          right: 0,
                          marginTop: "0em",
                          position: "absolute",
                          width: "40vw",
                        }}
                      /> */}
                    </div>
                  </div>
                </section>
              </Form>
            </Modal>
            <Modal
              width={1000}
              style={{
                top: 20,
              }}
              // title="Find a Part"
              visible={this.state.visibleFindPart}
              onCancel={this.handleCancelFindPart}
            >
              <Form
                style={{ maxWidth: "80vw", margin: "auto" }}
                id="book-service"
                onFinish={this.handleSubmitPart}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div
                          className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                          style={{
                            borderRadius: "1.5em",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="m-b-lg-10">
                            <div className="heading-1 text-align-lg-center">
                              <h3>FIND A PART</h3>
                            </div>
                            <p className="m-b-lg-20 text-align-lg-center">
                              Fill in the form below and one of our stock
                              specialists will get in touch
                            </p>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Car Details </h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="year"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Year" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="make"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Make" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="model"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Model" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="derivative"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Derivative" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Item
                                  name="details"
                                  rules={[{ required: false, message: false }]}
                                  hasFeedback
                                >
                                  <StyledTextArea
                                    placeholder="What specific part(s) are you looking for?"
                                    showCount
                                    maxLength={250}
                                    style={{
                                      height: 120,
                                      border: "1px solid black",
                                    }}
                                  //onChange={onChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="name"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Name" />
                                </Form.Item>
                              </div>

                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="number"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Number" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: false,
                                      type: "email",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Email" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <Form.Item
                                  name="branch"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledSelect
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                    placeholder="Region"
                                  //onChange={this.setCarEngineCapacity}
                                  >
                                    <OptGroup label="Klerksdorp">
                                      <Option
                                        key={0}
                                        value={this.state.klerksdorpMail}
                                      >
                                        Klerksdorp
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Potchefstroom">
                                      <Option
                                        key={1}
                                        value={this.state.potchefstroomMail}
                                      >
                                        Potchefstroom
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Mahikeng">
                                      <Option
                                        key={3}
                                        value={this.state.mahikengMail}
                                      >
                                        Mahikeng
                                      </Option>
                                    </OptGroup>
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              {/* <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="date"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledDatePicker
                                    //defaultValue={moment(moment(), dateFormat)}
                                    format={dateFormat}
                                    style={{
                                      height: 50,
                                      border: "1px solid black",
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </div> */}

                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",

                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>{" "}
                                </Form.Item>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    //marginBottom: "2.5em",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/terms"
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/privacy-policy"
                                    >
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                        alt="logo"
                        style={{
                          marginTop: "0em",
                          zIndex: -1,
                          left: 0,
                          position: "absolute",
                          width: "40vw",
                        }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                        alt="logo"
                        style={{
                          zIndex: -1,
                          right: 0,
                          marginTop: "0em",
                          position: "absolute",
                          width: "40vw",
                        }}
                      /> */}
                    </div>
                  </div>
                </section>
              </Form>
            </Modal>
          </div>
        ) : (
          //mobiles
          <div>
            <div
              style={{
                // width: "100vw",
                margin: "auto",
              }}
            >
              <img
                style={{
                  width: "106vw",
                  position: "absolute",
                  height: window.isPhone ? "32em" : "32em",
                  objectFit: "cover",
                  marginTop: "0em",
                  right: 0,
                  zIndex: 0,
                }}
                src={`${process.env.PUBLIC_URL}/images/service-background-image-mobile.png`}
                alt="logo"
              />
              <div className="row " style={{ height: "32em" }}>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="banner-item-home  no-bg color-inher-home text-align-lg-right ">
                    <Row
                      style={{
                        margin: "auto",
                        paddingTop: "2em",
                        paddingBottom: "1em",
                      }}
                    >
                      <Col
                        xs={24}
                        md={15}
                        style={{
                          textAlign: "left",
                          paddingTop: "1.3em",
                        }}
                      >
                        <h1
                          style={{
                            color: "white",
                            marginTop: "-0.4em",
                            fontSize: 12,
                            margin: "auto",
                            textAlign: "center",
                          }}
                          className={
                            window.isPhone
                              ? "f-weight-600 f-15"
                              : "f-weight-600 f-15"
                          }
                        >
                          Welcome to Mega Theron{" "}
                          <span style={{ color: "red" }}>SERVICE CENTER</span>
                        </h1>
                        <h1
                          style={{
                            color: "white",
                            marginTop: "-0.9em",
                            whiteSpace: "normal",
                            fontSize: 30,
                            fontWeight: 300,
                            textTransform: "none",
                            lineHeight: "1.2em",
                            textAlign: "center",
                          }}
                        >
                          Discover the
                          <br />
                          Mega Theron Difference
                        </h1>
                        <br />
                        <p
                          style={{
                            color: "white",
                            fontSize: 15,
                            fontWeight: 300,
                            marginTop: "-1.6em",
                            textAlign: "center",
                            paddingLeft: "1em",
                            paddingRight: "1em",
                          }}
                        >
                          At Mega Theron, we're your trusted destination for
                          expert car care and exceptional service. Our skilled
                          mechanics and affordable prices ensure your vehicle
                          receives the top-quality care it deserves.
                          <br />
                          <br />
                          <span style={{ fontWeight: 500, fontSize: "12px" }}>
                            We provide service & parts for a wide range of
                            vehicle makes
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        position: "absolute",
                        overflow: "hidden",
                        left: 0,
                        width: "96vw",
                        marginTop: "-6em",
                        // left: "-20em"
                      }}
                    >
                      <Col xs={24} md={24}>
                        <div id="slideshow">
                          <div class="container1">
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/bmw-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/audi-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/chev-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/jac-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/ford-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/merc-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/ww-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/hyundai-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "11em",
                                  marginTop: "3.5em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/gwm-image-portrait.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/haval-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginTop: "2.7em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/chery-image-portrait.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/bmw-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/audi-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/chev-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/jac-image.png`}
                              ></img>
                            </section>{" "}
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/ford-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/merc-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "10em" }}
                                src={`${process.env.PUBLIC_URL}/images/ww-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/hyundai-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "12em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/gwm-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "14em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/haval-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{ textAlign: "left", width: "12em" }}
                                src={`${process.env.PUBLIC_URL}/images/chery-image.png`}
                              ></img>
                            </section>
                            <section>
                              <img
                                className="SlideImage"
                                layout={"fill"}
                                style={{
                                  textAlign: "left",
                                  width: "17em",
                                  marginBottom: "-12.5em",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/more-image.png`}
                              ></img>
                            </section>
                          </div>
                        </div>
                        {/* <img
                        src={`${process.env.PUBLIC_URL}/images/jac-image.png`}
                        alt="Logo"
                        style={{ width: "28em", margin: "auto", float: "left", marginRight: "-3em" }}
                      /> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "49.5em",
              }}
            >
              <Row
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100vw",
                  height: "23.5em",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Col
                  span={24}
                  style={{
                    backgroundColor: "#242424",
                    paddingTop: "1.5em",
                    paddingBottom: "2.5em",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 19,
                      fontWeight: 500,
                      color: "white",
                      marginBottom: "-1.1em",
                    }}
                  >
                    GET A QUOTE
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 35,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginBottom: "-0.3em",
                    }}
                  >
                    Easy & Hassle-free
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginTop: 0,
                      marginBottom: "-1em",
                    }}
                  >
                    Request your service quote
                  </h1>
                  <br />
                  {/* <Link to="/getQuote"> */}
                  <Button
                    onClick={this.showModalGetQuote}
                    style={{
                      fontFamily: "Roboto",
                      border: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderRadius: "2em",
                      backgroundColor: "#CB2228",
                      color: "white",
                      height: "47px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                    type="primary"
                  // htmlType="submit"
                  >
                    Get Quote
                  </Button>
                  {/* </Link> */}
                </Col>
                <Col
                  span={24}
                  style={{
                    backgroundImage: `url("/images/book-red-image.png")`,
                    paddingTop: "1.5em",
                    paddingBottom: "2.5em",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 19,
                      fontWeight: 500,
                      color: "white",
                      marginBottom: "-1.1em",
                    }}
                  >
                    SERVICE CENTER
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 35,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginBottom: "-0.3em",
                    }}
                  >
                    Book a Service
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginTop: 0,
                      marginBottom: "-1em",
                    }}
                  >
                    Car in need of a service? Book one now
                  </h1>
                  <br />
                  {/* <Link to="/bookService"> */}
                  <Button
                    onClick={this.showModalservicecenter}
                    style={{
                      fontFamily: "Roboto",
                      border: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderRadius: "2em",
                      backgroundColor: "white",
                      color: "#CB2228",
                      height: "47px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                    type="primary"
                  // htmlType="submit"
                  >
                    Book a Service
                  </Button>
                  {/* </Link> */}
                </Col>
                <Col
                  span={24}
                  style={{
                    backgroundColor: "#242424",
                    paddingTop: "1.5em",
                    paddingBottom: "2.5em",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 19,
                      fontWeight: 500,
                      color: "white",
                      marginBottom: "-1.1em",
                    }}
                  >
                    FIND A PART
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 35,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginBottom: "-0.3em",
                    }}
                  >
                    Get the Perfect Part
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      fontWeight: 500,
                      color: "white",
                      textTransform: "none",
                      marginTop: 0,
                      marginBottom: "-1em",
                    }}
                  >
                    Let us find the part you need
                  </h1>
                  <br />
                  {/* <Link to="/getPart"> */}
                  <Button
                    onClick={this.showModalFindPart}
                    style={{
                      fontFamily: "Roboto",
                      border: "none",
                      fontSize: "13px",
                      fontWeight: 400,
                      borderRadius: "2em",
                      backgroundColor: "#CB2228",
                      color: "white",
                      height: "47px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                    }}
                    type="primary"
                  // htmlType="submit"
                  >
                    Get Your Part
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            </div>
            <Row>
              <Col
                span={24}
                style={{
                  marginTop: "4.2em",
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: 38,
                    textTransform: "none",
                  }}
                >
                  Why <span style={{ color: "red" }}>Choose Us?</span>
                </h1>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "-1em",
              }}
            >
              <Col
                span={24}
                style={{
                  paddingLeft: "1.5em",
                  zIndex: 2,
                }}
              >
                <img
                  style={{
                    display: "flex",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/mechanic-image.png`}
                  width={40}
                  height={48}
                  alt="mechanic-image"
                />
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 22,
                    fontWeight: 500,
                    textTransform: "none",
                    marginTop: "-2.7em",
                    paddingLeft: "2.5em",
                  }}
                >
                  Top-Notch Service
                </h1>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    paddingLeft: "4.5em",
                    marginTop: "-1em",
                    lineHeight: "1.2em",
                    paddingRight: "1em",
                  }}
                >
                  Our service center is staffed with highly skilled and
                  experienced mechanics who are dedicated to providing top-notch
                  service for your vehicle.
                </p>
              </Col>
              <Col
                span={24}
                style={{
                  paddingLeft: "1.5em",
                  paddingTop: "0.5em",
                }}
              >
                <img
                  style={{
                    display: "flex",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/tag-image.png`}
                  width={46}
                  height={61}
                  alt="mechanic-image"
                />
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 22,
                    fontWeight: 500,
                    textTransform: "none",
                    marginTop: "-3.1em",
                    paddingLeft: "2.5em",
                  }}
                >
                  Affordable Excellence
                </h1>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    paddingLeft: "4.5em",
                    marginTop: "-1em",
                    lineHeight: "1.2em",
                    paddingRight: "1em",
                  }}
                >
                  At our service center, we believe that exceptional car care
                  should be accessible to all.
                </p>
              </Col>
            </Row>
            <Row
              style={{
                margin: "auto",
                maxWidth: "85em",
                marginTop: "2em",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: 38,
                    textTransform: "none",
                  }}
                >
                  Meet the <span style={{ color: "red" }}>Team</span>
                </h1>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    marginTop: "-2em",
                    marginBottom: "4em",
                    lineHeight: "1.2em",
                    paddingLeft: "1em",
                    padingRight: "1em",
                  }}
                >
                  We're dedicated to ensuring that superior automative service
                  is within reach for all our customers.
                </p>
              </Col>
            </Row>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Klerksdorp" key="1">
                <Row
                  gutter={10}
                  style={{
                    margin: "auto",
                    marginTop: "3em",
                  }}
                >
                  {/* <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/11-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/3-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  {/* <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/6-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/8-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/12-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  {/* <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/7-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  {/* <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/5-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  {/* <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/1-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col> */}
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/2-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/4-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/9-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/10-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/13-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Klerksdorp-employees-images/14-Klerksdorp.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Mahikeng" key="2">
                <Row
                  gutter={10}
                  style={{
                    margin: "auto",
                    marginTop: "3em",
                  }}
                >
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/5-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/4-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/1-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/6-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/8-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/7-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/2-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/3-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>

                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/9-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                  <Col span={12} style={{ marginTop: "0.7em" }}>
                    <Image
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.PUBLIC_URL}/Mahikeng-employees-images/10-Mahikeng.jpg`}
                      alt="employee-image"
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            <Row
              style={{
                marginTop: "1.5em",
                // margin: "auto",
                // maxWidth: "85em",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: 35,
                    textTransform: "none",
                    marginTop: "0.7em",
                  }}
                >
                  Testimonials
                </h1>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    marginTop: "-1em",
                    lineHeight: "1.2em",
                  }}
                >
                  Discover what our customers have to say about their
                  experiences with us
                </p>
              </Col>
            </Row>
            <Row
              style={{
                // marginTop: "2em",
                marginBottom: "2em",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <Slider {...settings}>
                  {/* <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Lee-Allen
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Thank you very much, to you Andrea and your team! <br /> Absolutely amazing service and I am beyond myself with happiness!"
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Lee-Allen
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Quick compliment and recommendation😁high level of professionalism😉Angie,Hermann, Michelle and Richard🙌great service"
                    </p>
                  </div> */}
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Jeanette Rossouw
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Wow, excellent service from Chery Klerksdorp and
                      Reghardt. From getting information to the delivery of the
                      vehicle, it was flawless. What a great pleasure doing
                      business with you guys! Thank you to the whole team at
                      Chery Klerksdorp, especially Reghardt. Well done! I will
                      definitely recommend this branch."
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Lebo M.
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Hi, good day! GWM Klerksdorp provided such marvelous
                      service. I'm truly at a loss for words to express my
                      appreciation. <br />
                      The level of service we received goes beyond mere words.
                      <br />
                      Thank you."
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Lucky Z.
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "Thanks for the service you rendered to me since the start
                      of our journey in the beginning of December last year.
                      Today I got the beautiful beast delivered to me. I am in
                      love with it and I enjoy everything! The luxurious and
                      comfortable interior of this machine drives me crazy. It
                      makes me feel like I'm in heaven.
                      <br />
                      Thank You."
                    </p>
                  </div>
                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Debra
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "I met Norman today, but first via a phone call early this
                      morning and later via Whatsapp. Our Hawk bakkie needed its
                      first service and one small other problem. In no time
                      everything was arranged and done. Professional yet kind
                      and warm service, that is what we received! Thank you
                      Norman, your friendly smile has hooked this customer for a
                      long time! Thank you very much. And wow thanks for our
                      special gifts."
                    </p>
                  </div>

                  <div>
                    <h1
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 600,
                        fontSize: 18,
                        textTransform: "none",
                        color: "red",
                        marginBottom: 0,
                      }}
                    >
                      Nokuthula M.
                    </h1>
                    <Rate
                      disabled
                      defaultValue={5}
                      style={{
                        fontSize: 16,
                      }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "1.2em",
                        paddingTop: "1em",
                      }}
                    >
                      "I bought my car at Mega Theron Traders... since 2022 no
                      problems. I'm coming for the second ride soon"
                    </p>
                  </div>
                </Slider>
              </Col>
            </Row>
            <Modal
              width={1000}
              style={{ top: 0 }}
              // title="Get a Quote"
              visible={this.state.visibleGetQuote}
              onCancel={this.handleCancelGetQuote}
            >
              <Form
                style={{ margin: "auto" }}
                id="book-service"
                onFinish={this.handleSubmitQuote}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div
                          className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                          style={{
                            borderRadius: "1.5em",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="m-b-lg-10">
                            <div className="heading-1 text-align-lg-center">
                              <h3>GET A QUOTE</h3>
                            </div>
                            <p className="m-b-lg-20 text-align-lg-center">
                              Fill in the form below and one of our stock
                              specialists will get in touch
                            </p>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Car Details </h3>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="year"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Year" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="make"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Make" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="model"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Model" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="derivative"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Derivative" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Item
                                  name="details"
                                  rules={[{ required: false, message: false }]}
                                  hasFeedback
                                >
                                  <StyledTextArea
                                    placeholder="How can we assist with your vehicle today?"
                                    showCount
                                    maxLength={250}
                                    style={{
                                      height: 120,
                                      border: "1px solid black",
                                    }}
                                  //onChange={onChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>
                            <div className="row">
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="name"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Name" />
                                </Form.Item>
                              </div>

                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="number"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Number" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: false,
                                      type: "email",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Email" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <Form.Item
                                  name="branch"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledSelect
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                    placeholder="Region"
                                  //onChange={this.setCarEngineCapacity}
                                  >
                                    <OptGroup label="Klerksdorp">
                                      <Option
                                        key={0}
                                        value={this.state.klerksdorpMail}
                                      >
                                        Klerksdorp
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Potchefstroom">
                                      <Option
                                        key={1}
                                        value={this.state.potchefstroomMail}
                                      >
                                        Potchefstroom
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Mahikeng">
                                      <Option
                                        key={3}
                                        value={this.state.mahikengMail}
                                      >
                                        Mahikeng
                                      </Option>
                                    </OptGroup>
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              {/* <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="date"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledDatePicker
                                    //defaultValue={moment(moment(), dateFormat)}
                                    format={dateFormat}
                                    style={{
                                      height: 50,
                                      border: "1px solid black",
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </div> */}

                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",

                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>{" "}
                                </Form.Item>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    //marginBottom: "2.5em",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/terms"
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/privacy-policy"
                                    >
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                        alt="logo"
                        style={{
                          marginTop: "0em",
                          zIndex: -1,
                          left: 0,
                          position: "absolute",
                          width: "40vw",
                        }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                        alt="logo"
                        style={{
                          zIndex: -1,
                          right: 0,
                          marginTop: "0em",
                          position: "absolute",
                          width: "40vw",
                        }}
                      /> */}
                    </div>
                  </div>
                </section>
              </Form>
            </Modal>
            <Modal
              width={1000}
              style={{ top: 0 }}
              // title="Book a Service"
              visible={this.state.visibleservicecenter}
              onCancel={this.handleCancelservicecenter}
            >
              <Form
                style={{ margin: "auto" }}
                id="book-service"
                onFinish={this.handleSubmit}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div
                          className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                          style={{
                            borderRadius: "1.5em",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="m-b-lg-10">
                            <div className="heading-1 text-align-lg-center">
                              <h3>Book Vehicle Service</h3>
                            </div>
                            <p className="m-b-lg-20 text-align-lg-center">
                              Fill in the form below and one of our stock
                              specialists will get in touch
                            </p>
                          </div>
                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Car Details </h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="year"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Year" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="make"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Make" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="model"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Model" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="derivative"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Derivative" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Item
                                  name="details"
                                  rules={[{ required: false, message: false }]}
                                  hasFeedback
                                >
                                  <StyledTextArea
                                    placeholder="Anything we need to know about your vehicle?"
                                    showCount
                                    maxLength={250}
                                    style={{
                                      height: 120,
                                      border: "1px solid black",
                                    }}
                                  //onChange={onChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="name"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Name" />
                                </Form.Item>
                              </div>

                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="number"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Number" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: false,
                                      type: "email",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Email" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <Form.Item
                                  name="branch"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledSelect
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                    placeholder="Region"
                                  //onChange={this.setCarEngineCapacity}
                                  >
                                    <OptGroup label="Klerksdorp">
                                      <Option
                                        key={0}
                                        value={this.state.klerksdorpMail}
                                      >
                                        Klerksdorp
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Potchefstroom">
                                      <Option
                                        key={1}
                                        value={this.state.potchefstroomMail}
                                      >
                                        Potchefstroom
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Mahikeng">
                                      <Option
                                        key={3}
                                        value={this.state.mahikengMail}
                                      >
                                        Mahikeng
                                      </Option>
                                    </OptGroup>
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="date"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledDatePicker
                                    //defaultValue={moment(moment(), dateFormat)}
                                    format={dateFormat}
                                    style={{
                                      height: 50,
                                      border: "1px solid black",
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </div>

                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",

                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>{" "}
                                </Form.Item>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    //marginBottom: "2.5em",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/terms"
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/privacy-policy"
                                    >
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                        alt="logo"
                        style={{
                          marginTop: "0em",
                          zIndex: -1,
                          left: 0,
                          position: "absolute",
                          width: "40vw",
                        }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                        alt="logo"
                        style={{
                          zIndex: -1,
                          right: 0,
                          marginTop: "0em",
                          position: "absolute",
                          width: "40vw",
                        }}
                      /> */}
                    </div>
                  </div>
                </section>
              </Form>
            </Modal>
            <Modal
              width={1000}
              style={{
                top: 0,
              }}
              // title="Find a Part"
              visible={this.state.visibleFindPart}
              onCancel={this.handleCancelFindPart}
            >
              <Form
                style={{ margin: "auto" }}
                id="book-service"
                onFinish={this.handleSubmitPart}
                initialValues={{
                  remember: true,
                }}
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                  <div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div
                          className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                          style={{
                            borderRadius: "1.5em",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="m-b-lg-10">
                            <div className="heading-1 text-align-lg-center">
                              <h3>FIND A PART</h3>
                            </div>
                            <p className="m-b-lg-20 text-align-lg-center">
                              Fill in the form below and one of our stock
                              specialists will get in touch
                            </p>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Car Details </h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="year"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Year" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="make"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Make" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="model"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Model" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="derivative"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Derivative" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <Form.Item
                                  name="details"
                                  rules={[{ required: false, message: false }]}
                                  hasFeedback
                                >
                                  <StyledTextArea
                                    placeholder="What specific part(s) are you looking for?"
                                    showCount
                                    maxLength={250}
                                    style={{
                                      height: 120,
                                      border: "1px solid black",
                                    }}
                                  //onChange={onChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>

                            <div className="row">
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="name"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Name" />
                                </Form.Item>
                              </div>

                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="number"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Number" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="email"
                                  rules={[
                                    {
                                      required: true,
                                      message: false,
                                      type: "email",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <StyledInput placeholder="Email" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-4">
                                <Form.Item
                                  name="branch"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledSelect
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                    placeholder="Region"
                                  //onChange={this.setCarEngineCapacity}
                                  >
                                    <OptGroup label="Klerksdorp">
                                      <Option
                                        key={0}
                                        value={this.state.klerksdorpMail}
                                      >
                                        Klerksdorp
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Potchefstroom">
                                      <Option
                                        key={1}
                                        value={this.state.potchefstroomMail}
                                      >
                                        Potchefstroom
                                      </Option>
                                    </OptGroup>
                                    <OptGroup label="Mahikeng">
                                      <Option
                                        key={3}
                                        value={this.state.mahikengMail}
                                      >
                                        Mahikeng
                                      </Option>
                                    </OptGroup>
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              {/* <div className="col-sm-4 col-md-4 col-lg-4">
                                <Form.Item
                                  name="date"
                                  rules={[{ required: true, message: false }]}
                                  hasFeedback
                                >
                                  <StyledDatePicker
                                    //defaultValue={moment(moment(), dateFormat)}
                                    format={dateFormat}
                                    style={{
                                      height: 50,
                                      border: "1px solid black",
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </div> */}

                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",

                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>{" "}
                                </Form.Item>{" "}
                                <p
                                  style={{
                                    color: "black",
                                    //marginBottom: "2.5em",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/terms"
                                    >
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link
                                      style={{ color: "black" }}
                                      to="/privacy-policy"
                                    >
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                        alt="logo"
                        style={{
                          marginTop: "0em",
                          zIndex: -1,
                          left: 0,
                          position: "absolute",
                          width: "40vw",
                        }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                        alt="logo"
                        style={{
                          zIndex: -1,
                          right: 0,
                          marginTop: "0em",
                          position: "absolute",
                          width: "40vw",
                        }}
                      /> */}
                    </div>
                  </div>
                </section>
              </Form>
            </Modal>

            <Modal
              visible={this.state.visible}
              style={{ textAlign: "center" }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Logo"
                style={{ width: "65%", margin: "auto" }}
              />
              <h3
                style={{
                  marginTop: "1em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Thank you for contacting
                <br />
                <span style={{ color: "rgb(214, 54, 41)" }}>
                  The Mega Theron Group.
                </span>{" "}
                <br />
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly.
                </span>{" "}
                <br />
              </h3>{" "}
              <br />
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default servicecenter;
