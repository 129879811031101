import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { fetchFiltredStock } from "../../redux/actions";
import StockDetails from "./StockDetails";
import StockContainer from "./StockContainerMahikeng";

class StockshopLichtenburg extends Component {
  constructor(props) {
    super(props);
    this.state = { stock: [] };
  }
  componentDidMount() {
    this.props.fetchFiltredStock(this.props.filters);
    // fetchStock()
    //   .then((suc) => {
    //     //handle die data hier bv this.setState({promocodes:suc.data})
    //     this.setState({ stock: suc.allStock });
    //     console.log(suc);
    //   })
    //   .catch((e) => {
    //     //handle alle errors hier en display error message of iets
    //     console.log(e);
    //   });
  }
  render() {
    return (
      <div
        className="wrap-body-inner"
        style={
          {
            //minHeight: "1200px"
          }
        }
      >
        <BrowserRouter>
          <div>
            <Route
              exact
              path="/mahikeng"
              render={() => (
                <StockContainer
                  filters={this.props.filters}
                  stockList={this.props.stock}
                />
              )}
            />
            <Route
              exact
              to={`/stocko/${this.props.stock.ID}`}
              component={StockDetails}
            />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps({ stock }) {
  return { stock };
}

export default connect(mapStateToProps, { fetchFiltredStock })(
  StockshopLichtenburg
);
