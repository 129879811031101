import React, { Component } from "react";
//import { Row, Col, Divider } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../../redux/actions";
import CurrencyFormat from "react-currency-format";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class StockItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
    };
  }
  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }
  componentDidMount() {
    var stock = this.props.stock;

    if (
      this.props.wishlistproducts.filter(function (e) {
        return e.ID === stock.ID;
      }).length === 1
    ) {
      this.setState({ isInWishList: true });
    }
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render() {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "#F91700",
    //    fontWeight: "bold",
    //  };
    //}

    const { stock } = this.props;
    return (
      //<Fade>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="product-item hover-img">
          {/*<i
                          id={JSON.stringify(stock)}
                          //className={
                          //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                          //}
                          className={"fa fa-heart"}
                          style={{
                            background: "#ecececbe",
                            borderRadius: "0em 0em 0em 0.8em",
                            padding: "1em",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: this.state.isInWishList ? "red" : "grey",
                          }}
                          onClick={this.ToggleCarWishlist.bind(this)}
                        />*/}
          {stock.onSale === true ? (
            <span
              style={{
                background: "rgb(254, 95, 92)",
                borderRadius: "5em",
                padding: "0.7em",
                paddingTop: "1.1em",
                paddingBottom: "1em",
                position: "absolute",
                left: 10,
                top: 10,
                color: "white",
                fontSize: 11,
              }}
            >
              SALE
            </span>
          ) : null}
          {stock.NewUsed ? (
            <span
              style={{
                background:
                  stock.NewUsed === "New"
                    ? "#f919007d"
                    : stock.NewUsed === "Demo"
                      ? "#f919007d"
                      : "#f919007d",
                borderRadius: "0em",
                padding: "0.3em",
                paddingRight: "0.8em",
                paddingLeft: "0.8em",
                position: "absolute",
                right: 10,
                top: 158,
                width: "4.3em",
                color: "white",
              }}
            >
              {stock.NewUsed}
            </span>
          ) : null}
          {stock.Year ? (
            <span
              style={{
                background: "#f919007d",
                borderRadius: "0em",
                padding: "0.3em",
                paddingRight: "0.8em",
                paddingLeft: "0.8em",
                position: "absolute",
                right: stock.NewUsed ? 75 : 10,
                top: 158,
                color: "white",
              }}
            >
              {stock.Year}
            </span>
          ) : null}
          <Link
            className="product-img"
            to={`/stocko/${stock.ID}`}
            onClick={() => {
              this.props.fetchSingleStock(stock);
              window.scrollTo(0, 0); // Scroll to the top
            }}
          >
            <img
              src={stock.Pic[0]
                ? stock.Pic[0]._
                : `${process.env.PUBLIC_URL}/images/coming-soon.png`}
              alt=""
            />
          </Link>
          <div className="product-caption">
            <h4
              className="product-name"
              style={{ marginBottom: "0em", marginTop: "-1em" }}
            >
              <Link
                to={`/stocko/${stock.ID}`}
                onClick={() => {
                  this.props.fetchSingleStock(stock);
                  window.scrollTo(0, 0); // Scroll to the top
                }}
              >
                <span
                  style={{
                    display: "block",
                    textTransform: "uppercase",
                    whiteSpace: "nowrap",
                  }}
                >
                  {stock.Brand}
                  <p
                    className="f-14"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      paddingRight: "3em",
                      overflow: "hidden",
                      width: "21em",
                    }}
                  >
                    {/* 
                              <br />*/}
                    {stock.Model}
                  </p>
                </span>
              </Link>
            </h4>
            <div className="product-name">
              <span
                className="f-13 m-t-lg-5"
                style={{
                  whiteSpace: "nowrap",
                  float: "left",
                  textAlign: "left",
                  fontWeight: 400,
                  color: "black",
                }}
              >
                {/* {stock.RegionImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.RegionImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                <img
                  src={
                    stock.DealershipID.includes("MT003")
                      ? `${process.env.PUBLIC_URL}/images/logo.png`
                      : stock.DealershipID.includes("MT001")
                        ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                        : stock.DealershipID.includes("KMK18")
                          ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                          : stock.DealershipID.includes("MT005")
                            ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                            : stock.DealershipID.includes("MT008")
                              ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                              : stock.Brand.includes("GWM")
                                ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                : stock.Brand.includes("HAVAL")
                                  ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                  : null // Return null for the default case
                  }
                  alt=""
                  style={{
                    position: "absolute",
                    marginTop:
                      stock.DealershipID.includes("MT003") ||
                        stock.DealershipID.includes("MT001")
                        ? "-2.5em"
                        : stock.DealershipID.includes("MT008")
                          ? "-2.1em"
                          : stock.Brand.includes("HAVAL")
                            ? "-1.8em"
                            : "-1.9em",
                    objectFit: "contain",
                    width:
                      stock.DealershipID.includes("MT003") ||
                        stock.DealershipID.includes("MT001")
                        ? "8.5em"
                        : stock.DealershipID.includes("MT005")
                          ? "7em"
                          : stock.DealershipID.includes("MT008")
                            ? "3em"
                            : "5em",
                  }}
                />
                {stock.DealershipID.includes("MT001") ? "Mega Theron Traders" :
                  stock.DealershipID.includes("MT002") ? "JAC Klerksdorp" :
                    stock.DealershipID.includes("MT003") ? "GWM/Haval Klerksdorp" :
                      stock.DealershipID.includes("MT004") ? "GWM/Haval Potch" :
                        stock.DealershipID.includes("MT005") ? "Mahindra Mahikeng" :
                          stock.DealershipID.includes("MT006") ? "GWM/Haval Mahikeng" :
                            stock.DealershipID.includes("MT007") ? "Lichtenburg" :
                              stock.DealershipID.includes("MT008") ? "Chery Klerksdorp" :
                                stock.DealershipID.includes("KMK18") ? "Kia Mahikeng" :
                                  "Unknown Dealership"}
              </span>
              <span
                className="f-23  m-t-lg-0 mn-cl"
                style={{ float: "right" }}
              >
                <CurrencyFormat
                  value={parseFloat(stock.Price)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  //format='R### ### ### ###'
                  renderText={value => <div>{value}</div>}
                />
              </span>
            </div>
            <ul className="static-caption m-t-lg-20">
              <li>
                <i className="fa fa-clock-o"></i>
                {/*{stock.fuel}*/}
                {stock.FuelType}
              </li>
              <li>
                <i className="fa fa-tachometer"></i>
                {stock.Transmission}
              </li>
              <li>
                <i className="fa fa-road"></i>
                <CurrencyFormat
                  value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"km"}
                  renderText={value => (
                    <div style={{ display: "inline-block" }}>
                      {value}
                    </div>
                  )}
                />
              </li>
            </ul>
          </div>
        </div>
      </div >
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(StockItemCard);
